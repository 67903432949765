<nav
  class="fixed w-full max-w-screen-sm flex-1 flex flex-wrap items-center justify-between px-2 py-3 mb-3 border-solid border-b-2 bg-gray-50 shadow-md border-gray-100 z-50"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between items-center lg:w-auto px-4 lg:static lg:block lg:justify-start"
    >
      <button
        [hidden]="!showBackButton"
        class="fixed left-4 text-slate-500 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
        type="button"
        (click)="goBack()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>
      <a
        class="text-sm w-full text-center font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-slate-500"
      >
        {{ title }}
      </a>
      <!-- notification button -->
      <!-- <button
        class="fixed right-4 text-slate-500 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
          />
        </svg>
      </button> -->
    </div>
  </div>
</nav>
