import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  templateUrl: './not-found-page.component.html',
})
export class NotFoundPageComponent {
  constructor() {}
}
